import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function TermsPage() {
  return (
    <div className="min-h-screen bg-gray-50 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <Link to="/" className="inline-flex items-center text-accent hover:underline mb-8">
          <ArrowLeft className="mr-2 h-5 w-5" />
          Volver al inicio
        </Link>
        
        <h1 className="text-4xl font-bold text-primary mb-8">Términos y Condiciones</h1>
        
        <div className="prose prose-lg">
          <h2>1. Suscripción y Pagos</h2>
          <p>El servicio de Webber está disponible exclusivamente bajo suscripción mensual pagada por adelantado. 
             La contratación del servicio implica la aceptación de los siguientes términos:</p>
          <ul>
            <li>Suscripción mensual renovable automáticamente</li>
            <li>Pago adelantado al inicio de cada período</li>
            <li>No se ofrece período de prueba gratuito</li>
            <li>Precios en pesos argentinos (ARS) con IVA incluido</li>
            <li>Facturación electrónica según normativa AFIP</li>
          </ul>
          
          <h2>2. Facturación y Modificaciones</h2>
          <ul>
            <li>La facturación se realiza por adelantado al inicio de cada período mensual</li>
            <li>Los precios están sujetos a modificaciones con previo aviso de 30 días</li>
            <li>No se realizan reembolsos por períodos parciales</li>
            <li>Los pagos se procesan a través de plataformas autorizadas por el BCRA</li>
          </ul>
          
          <h2>3. Cancelación</h2>
          <p>La cancelación del servicio debe solicitarse con 15 días de anticipación al siguiente ciclo de facturación. 
             Proceso de cancelación:</p>
          <ul>
            <li>Solicitud por escrito al correo oficial</li>
            <li>Confirmación de recepción en 24-48 horas hábiles</li>
            <li>Procesamiento de la baja al final del período vigente</li>
            <li>No se realizan reembolsos por cancelaciones durante el período activo</li>
          </ul>
          
          <h2>4. Derechos del Usuario</h2>
          <p>De acuerdo con la legislación vigente, el usuario tiene derecho a:</p>
          <ul>
            <li>Información clara y detallada del servicio</li>
            <li>Revocación dentro de los 10 días de contratación</li>
            <li>Trato equitativo y digno</li>
            <li>Presentación de reclamos ante autoridades competentes</li>
          </ul>
        </div>
      </div>
    </div>
  );
}