import { MessageSquare, Users2 } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ContactSection.css';

export default function ContactSection() {
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    mensaje: ''
  });

  const [response, setResponse] = useState({
    type: '',
    message: ''
  });

  const [loading, setLoading] = useState(false); // Estado para manejar la carga

  const API_URL = import.meta.env.VITE_API_URL;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true); // Iniciar carga al enviar el formulario
    
    try {
      console.log('Iniciando petición a:', API_URL);
      console.log('Datos a enviar:', formData);
      
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Client-Origin': window.location.origin,
          'X-Client-Host': window.location.hostname
        },
        body: JSON.stringify(formData),
        credentials: 'include'
      });

      console.log('Respuesta recibida:', {
        status: response.status,
        statusText: response.statusText,
        headers: Object.fromEntries(response.headers.entries())
      });

      if (response.ok) {
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'conversion', {'send_to': 'AW-16778743802/BsbACIGnsesZEPqf3cA-'});
        }
        
        setResponse({
          type: 'success',
          message: 'Message sent successfully!'
        });
        
        setTimeout(() => {
          setFormData({ nombre: '', email: '', mensaje: '' });
          navigate('/thank-you');
        }, 1500);
      } else {
        const errorData = await response.json().catch(e => ({
          message: 'No se pudo parsear la respuesta JSON',
          originalError: e.message
        }));
        console.error('Error data:', errorData);
        throw new Error(errorData.message || 'Error sending message');
      }
    } catch (error: any) {
      console.error('Error completo:', {
        name: error.name,
        message: error.message,
        stack: error.stack,
        cause: error.cause
      });

      setResponse({
        type: 'error',
        message: error instanceof Error ? error.message : 'There was an error sending your message. Please try again.'
      });
      
      setTimeout(() => {
        setResponse({ type: '', message: '' });
      }, 5000);
    } finally {
      setLoading(false); // Finalizar carga al recibir respuesta
    }
  };

  return (
    <section id="contact" className="py-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="bg-white rounded-xl shadow-xl overflow-hidden">
          <div className="grid md:grid-cols-2">
            <div className="gradient-bg p-12 text-white">
              <h2 className="text-3xl font-bold mb-6">Get in Touch</h2>
              <p className="mb-8">Ready to transform your IT infrastructure? Contact us today for a free consultation.</p>
              <div className="space-y-4">
                <div className="flex items-center">
                  <MessageSquare className="mr-3" size={20} />
                  <span>+54 9 11 3691-7205</span>
                </div>
                <div className="flex items-center">
                  <Users2 className="mr-3" size={20} />
                  <span>24/7 Support Available</span>
                </div>
              </div>
            </div>
            <div className="p-12">
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-accent focus:ring-accent"
                    required
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-accent hover:bg-accent/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent items-center gap-2"
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <div className="loading-spinner" />
                        Sending...
                      </>
                    ) : (
                      'Contact Us'
                    )}
                  </button>
                </div>
                {response.type && (
                  <div className={`mt-4 p-4 rounded-md ${
                    response.type === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
                  }`}>
                    {response.message}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}