import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function ThankYouPage() {
  return (
    <div className="min-h-screen bg-gray-50 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto text-center">
        <Link to="/" className="inline-flex items-center text-accent hover:underline mb-8">
          <ArrowLeft className="mr-2 h-5 w-5" />
          Back to Home
        </Link>
        
        <div className="bg-white p-8 rounded-xl shadow-lg">
          <h1 className="text-4xl font-bold text-primary mb-4">Thank You for Contacting Us!</h1>
          <p className="text-xl text-gray-600 mb-6">
            We have successfully received your message. Our team will get back to you as soon as possible.
          </p>
          <p className="text-gray-500">
            Estimated response time: 24-48 business hours
          </p>
        </div>
      </div>
    </div>
  );
}