import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function PrivacyPage() {
  return (
    <div className="min-h-screen bg-gray-50 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <Link to="/" className="inline-flex items-center text-accent hover:underline mb-8">
          <ArrowLeft className="mr-2 h-5 w-5" />
          Volver al inicio
        </Link>
        
        <article className="prose prose-lg max-w-none">
          <h1>Política de Privacidad</h1>
          <p>Última actualización: 16 de noviembre de 2024</p>
          
          <h2>Interpretación y Definiciones</h2>
          <h3>Interpretación</h3>
          <p>Las palabras cuya letra inicial está en mayúscula tienen significados definidos bajo las siguientes condiciones. 
             Las siguientes definiciones tendrán el mismo significado independientemente de que aparezcan en singular o en plural.</p>
          
          <h3>Definiciones</h3>
          <p>A los efectos de esta Política de Privacidad:</p>
          <ul>
            <li><strong>Cuenta</strong> significa una cuenta única creada para que Usted acceda a nuestro Servicio o partes de nuestro Servicio.</li>
            <li><strong>Empresa</strong> (referida como "la Empresa", "Nosotros", "Nos" o "Nuestro" en este Acuerdo) se refiere a Webber.</li>
            <li><strong>Cookies</strong> son pequeños archivos que se colocan en Su computadora, dispositivo móvil o cualquier otro dispositivo.</li>
            <li><strong>País</strong> se refiere a: Argentina</li>
            <li><strong>Dispositivo</strong> significa cualquier dispositivo que puede acceder al Servicio.</li>
            <li><strong>Datos Personales</strong> es cualquier información que se relaciona con un individuo identificado o identificable.</li>
          </ul>

          <h2>Recopilación y Uso de Sus Datos Personales</h2>
          <h3>Tipos de Datos Recopilados</h3>
          <h4>Datos Personales</h4>
          <p>Mientras usa Nuestro Servicio, podemos pedirle que nos proporcione cierta información de identificación personal que puede usarse para contactarlo o identificarlo. La información de identificación personal puede incluir, pero no se limita a:</p>
          <ul>
            <li>Dirección de correo electrónico</li>
            <li>Nombre y apellido</li>
            <li>Número de teléfono</li>
            <li>Datos de uso</li>
          </ul>

          <h3>Retención de Sus Datos Personales</h3>
          <p>La Empresa conservará sus Datos Personales solo durante el tiempo que sea necesario para los fines establecidos en esta Política de Privacidad. Conservaremos y utilizaremos Sus Datos Personales en la medida necesaria para cumplir con nuestras obligaciones legales, resolver disputas y hacer cumplir nuestros acuerdos y políticas legales.</p>

          <h3>Seguridad de Sus Datos Personales</h3>
          <p>La seguridad de Sus Datos Personales es importante para Nosotros, pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro. Si bien Nos esforzamos por utilizar medios comercialmente aceptables para proteger Sus Datos Personales, no podemos garantizar su seguridad absoluta.</p>

          <h2>Suscripción y Facturación</h2>
          <h3>Términos de la Suscripción</h3>
          <p>Al contratar nuestros servicios, usted acepta los siguientes términos de suscripción:</p>
          <ul>
            <li>La suscripción es mensual y se renueva automáticamente al final de cada período.</li>
            <li>El pago se realiza por adelantado al inicio de cada período mensual.</li>
            <li>No ofrecemos período de prueba gratuito.</li>
            <li>Los precios están expresados en pesos argentinos (ARS) e incluyen IVA.</li>
          </ul>

          <h3>Facturación y Pagos</h3>
          <p>Respecto a la facturación y los pagos:</p>
          <ul>
            <li>Emitimos factura electrónica conforme a las regulaciones de AFIP.</li>
            <li>Los pagos se procesan a través de plataformas seguras y autorizadas por el BCRA.</li>
            <li>La información de pago se almacena de manera encriptada cumpliendo con los estándares PCI DSS.</li>
            <li>Los precios pueden estar sujetos a modificaciones, las cuales serán notificadas con 30 días de anticipación.</li>
          </ul>

          <h3>Cancelación y Reembolsos</h3>
          <p>Sobre la cancelación del servicio:</p>
          <ul>
            <li>Puede cancelar su suscripción en cualquier momento con 15 días de anticipación al siguiente ciclo de facturación.</li>
            <li>La cancelación debe solicitarse por escrito a través de nuestro correo electrónico oficial.</li>
            <li>No realizamos reembolsos por períodos parciales no utilizados.</li>
            <li>Tras la cancelación, sus datos serán tratados según lo establecido en la Ley 25.326.</li>
          </ul>

          <h3>Derechos del Consumidor</h3>
          <p>De acuerdo con la Ley 24.240 de Defensa del Consumidor, usted tiene derecho a:</p>
          <ul>
            <li>Información clara y detallada sobre el servicio contratado.</li>
            <li>Conocer los términos y condiciones antes de la contratación.</li>
            <li>Recibir un trato equitativo y digno.</li>
            <li>Revocar la aceptación durante el plazo de 10 días corridos desde la contratación, sin responsabilidad alguna.</li>
            <li>Presentar reclamos ante la autoridad de aplicación correspondiente.</li>
          </ul>

          <h3>Protección de Datos en la Suscripción</h3>
          <p>En cumplimiento con la Ley 25.326 de Protección de Datos Personales:</p>
          <ul>
            <li>Sus datos de facturación y pago son recopilados con el único fin de procesar su suscripción.</li>
            <li>No compartimos su información financiera con terceros, excepto cuando sea necesario para procesar pagos.</li>
            <li>Tiene derecho a acceder, rectificar y suprimir sus datos personales.</li>
            <li>Puede solicitar información sobre el tratamiento de sus datos contactando a nuestro responsable de protección de datos.</li>
          </ul>

          <h3>Modificaciones al Servicio</h3>
          <p>Nos reservamos el derecho de:</p>
          <ul>
            <li>Modificar las características del servicio, notificando con 30 días de anticipación.</li>
            <li>Actualizar los precios, informando con 30 días de anticipación.</li>
            <li>Suspender el servicio en caso de incumplimiento de pago o uso indebido.</li>
            <li>Realizar mantenimientos programados, notificando con anticipación.</li>
          </ul>

          <h3>Resolución de Conflictos</h3>
          <p>Para la resolución de conflictos:</p>
          <ul>
            <li>Se aplicarán las leyes de la República Argentina.</li>
            <li>Las partes se someten a la jurisdicción de los tribunales ordinarios de la Ciudad Autónoma de Buenos Aires.</li>
            <li>Se reconoce la competencia de la Dirección Nacional de Defensa del Consumidor.</li>
          </ul>

          <h2>Contáctenos</h2>
          <p>Si tiene alguna pregunta sobre esta Política de Privacidad, puede contactarnos:</p>
          <ul>
            <li>Por correo electrónico: info@webber.com.ar</li>
            <li>Por teléfono: +54 9 11 3691-7205</li>
          </ul>
        </article>
      </div>
    </div>
  );
}