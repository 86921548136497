import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function RefundPage() {
  return (
    <div className="min-h-screen bg-gray-50 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <Link to="/" className="inline-flex items-center text-accent hover:underline mb-8">
          <ArrowLeft className="mr-2 h-5 w-5" />
          Volver al inicio
        </Link>
        
        <article className="prose prose-lg max-w-none">
          <h1>Política de Reembolsos</h1>
          <p>Última actualización: 20 de marzo de 2024</p>
          
          <h2>1. Disposiciones Generales</h2>
          <p>De acuerdo con la Ley de Defensa del Consumidor (Ley N° 24.240) y el Código Civil y Comercial de la Nación, establecemos la siguiente política de reembolsos:</p>

          <h2>2. Derecho de Revocación</h2>
          <ul>
            <li>Usted tiene derecho a revocar la contratación del servicio dentro de los 10 días corridos desde la activación.</li>
            <li>La revocación debe ser notificada por correo electrónico a info@webber.com.ar o mediante nuestro formulario de contacto.</li>
            <li>El reembolso se realizará por el mismo medio de pago utilizado en la contratación.</li>
          </ul>

          <h2>3. Reembolsos por Interrupción del Servicio</h2>
          <ul>
            <li>Se reembolsará la parte proporcional por interrupciones del servicio mayores a 24 horas.</li>
            <li>El cálculo se realizará sobre los días efectivos de interrupción.</li>
            <li>No se considerarán las interrupciones programadas y notificadas con anticipación.</li>
          </ul>

          <h2>4. Proceso de Reembolso</h2>
          <ol>
            <li>Enviar solicitud formal indicando motivo y datos de la contratación</li>
            <li>Nuestro equipo evaluará la solicitud en un plazo máximo de 48 horas hábiles</li>
            <li>Se notificará la resolución por correo electrónico</li>
            <li>El reembolso se procesará dentro de los 10 días hábiles de aprobada la solicitud</li>
          </ol>

          <h2>5. Exclusiones</h2>
          <p>No se realizarán reembolsos en los siguientes casos:</p>
          <ul>
            <li>Cancelación del servicio fuera del período de revocación</li>
            <li>Uso indebido o violación de los términos del servicio</li>
            <li>Interrupciones por causas de fuerza mayor</li>
            <li>Mantenimientos programados y notificados</li>
          </ul>

          <h2>6. Contacto</h2>
          <p>Para solicitudes de reembolso o consultas sobre esta política:</p>
          <ul>
            <li>Email: info@webber.com.ar</li>
            <li>Teléfono: +54 9 11 3691-7205</li>
            <li>Horario de atención: Lunes a Viernes de 9:00 a 18:00 (GMT-3)</li>
          </ul>
        </article>
      </div>
    </div>
  );
}