import Navbar from '../components/Navbar';
import { ArrowDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import ServicesSection from '../components/ServicesSection';
import ContactSection from '../components/ContactSection';

export default function HomePage() {
    return (
        <div className="min-h-screen bg-gray-50">
          <Navbar />
          
          {/* Hero Section */}
          <section className="gradient-bg pt-32 pb-20 min-h-[100vh] flex flex-col justify-between px-4 sm:px-6 lg:px-8 relative overflow-hidden">
            <div className="max-w-7xl mx-auto relative z-10">
              <div className="text-center">
                <h1 
                  className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-6 animate-fadeInUp"
                >
                  IT Support for Growing Businesses
                </h1>
                
                <div
                  className="mb-6 animate-fadeInUp animation-delay-300"
                >
                  <span className="text-4xl md:text-5xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-500 to-yellow-500">
                    $0 INITIAL COST
                  </span>
                  <span className="text-xl text-gray-200 ml-2">
                    + $45 per month
                  </span>
                </div>
                
                <p 
                  className="text-xl text-gray-200 mb-8 max-w-2xl mx-auto animate-fadeInUp animation-delay-400"
                >
                  Empowering small and medium businesses worldwide with enterprise-grade IT solutions
                </p>
                <div
                  className="animate-fadeInUp animation-delay-500"
                >
                  <a 
                    href="#services" 
                    className="inline-flex items-center bg-accent text-white px-6 py-3 rounded-lg font-medium hover:bg-accent/90 transition-colors"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>

            {/* Flecha simplificada */}
            <div className="text-center pb-8">
              <a 
                href="#services" 
                className="inline-block text-white/80 hover:text-white transition-colors"
                aria-label="View services"
              >
                <svg 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  className="mx-auto"
                >
                  <path 
                    d="M12 4L12 20M12 20L18 14M12 20L6 14" 
                    stroke="currentColor" 
                    strokeWidth="2.5" 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            </div>
          </section>
    
          {/* Secciones cargadas directamente */}
          <div className="content-visibility-auto">
            <ServicesSection />
            <ContactSection />
          </div>
    
          <footer className="bg-primary text-white py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
              <div className="grid md:grid-cols-3 gap-8 mb-8">
                <div>
                  <h3 className="text-xl font-bold mb-4">Webber</h3>
                  <p className="text-gray-300">Soluciones IT empresariales</p>
                </div>
                <div>
                  <h3 className="text-xl font-bold mb-4">Contacto</h3>
                  <p className="text-gray-300">Email: info@webber.com.ar</p>
                  <p className="text-gray-300">Tel: +54 9 11 3691-7205</p>
                </div>
                <div>
                  <h3 className="text-xl font-bold mb-4">Suscripción</h3>
                  <p className="text-gray-300">Servicio bajo suscripción mensual.</p>
                  <p className="text-gray-300">No incluye período de prueba gratuito.</p>
                </div>
              </div>
              
              <div className="border-t border-gray-700 pt-8">
                <div className="text-sm text-gray-300">
                  <p className="mb-4">© 2024 Webber. Todos los derechos reservados.</p>
                  <p className="mb-2">
                    Al utilizar nuestros servicios, usted acepta nuestros{' '}
                    <Link to="/terms" className="text-accent hover:underline">
                        Términos y Condiciones
                    </Link>
                    {', '}
                    <Link to="/privacy" className="text-accent hover:underline">
                        Política de Privacidad
                    </Link>
                    {' '}y{' '}
                    <Link to="/refund" className="text-accent hover:underline">
                        Política de Reembolsos
                    </Link>
                    .
                  </p>
                  <p className="text-xs">
                    El uso de nuestros servicios está sujeto al pago de una suscripción mensual. 
                    Los precios están sujetos a cambios previo aviso de 30 días. 
                    La cancelación debe realizarse con 15 días de anticipación al siguiente ciclo de facturación. 
                    No ofrecemos períodos de prueba gratuitos. 
                    El servicio se factura por adelantado al inicio de cada período mensual.
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </div>
    );
}