import { ArrowRight } from 'lucide-react';

export default function ServicesSection() {
  return (
    <section id="services" className="py-20 px-4 sm:px-6 lg:px-8 overflow-hidden">
      <div className="max-w-7xl mx-auto space-y-32">
        {/* Landing Page Section */}
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div className="relative" style={{ minHeight: '180px' }}>
            <div
              className="shadow-2xl rounded-lg overflow-hidden"
              style={{ transform: 'rotate(-6deg)' }}
            >
              <img 
                src="/resources/landing.webp" 
                alt="Landing page preview" 
                className="w-full h-auto rounded-lg border border-transparent shadow-lg"
                loading="lazy"
              />
            </div>
          </div>

          <div className="space-y-6">
            <h1 className="text-5xl font-bold leading-tight text-primary">
              Your Business Card to the World
            </h1>
            <h2 className="text-2xl text-gray-700">
              Landing Page
            </h2>
            <p className="text-xl text-gray-600">
              Get your business online with a professional website, business email, and custom domain. Everything you need to establish your digital presence.
            </p>

            <div className="space-y-4 mt-8">
              <div className="flex items-center gap-3">
                <ArrowRight className="text-accent h-6 w-6" />
                <span className="text-lg">Now you can create effective marketing campaigns</span>
              </div>
              <div className="flex items-center gap-3">
                <ArrowRight className="text-accent h-6 w-6" />
                <span className="text-lg">Your clients will be able to find you on the web</span>
              </div>
              <div className="flex items-center gap-3">
                <ArrowRight className="text-accent h-6 w-6" />
                <span className="text-lg">You can showcase your products and services easily</span>
              </div>
            </div>

            <div className="space-y-4">
              <div className="inline-block bg-accent/15 border-3 border-accent rounded-lg p-4 transform -rotate-2 shadow-lg">
                <span className="text-accent font-bold text-xl bg-yellow-100 px-2 py-1 rounded">Special Offer! 🎉</span>
                <div className="flex items-baseline gap-2 mt-2">
                  <span className="line-through text-gray-600 text-lg">$45/month</span>
                  <span className="text-4xl font-bold text-accent">$19/month</span>
                </div>
                <span className="text-accent font-semibold bg-white/80 px-2 py-1 rounded mt-1 inline-block">
                  The price of a McDonald's meal! 🍔
                </span>
              </div>
              
              <div className="flex gap-4">
                <a 
                  href="#contact"
                  className="inline-block bg-accent hover:bg-accent/90 text-white font-semibold py-4 px-8 rounded-lg transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl text-lg"
                >
                  Get Started Now
                </a>
                <a 
                  href="https://templates.webber.com.ar/landing"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-white hover:bg-gray-50 text-accent border-2 border-accent font-semibold py-4 px-8 rounded-lg transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl text-lg"
                >
                  See Demo
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* E-commerce Section */}
        <div className="gradient-bg py-20 px-4 -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="grid md:grid-cols-2 gap-12 items-center max-w-7xl mx-auto">
            <div className="order-2 md:order-1 space-y-6">
              <h2 className="text-5xl font-bold leading-tight text-white">
                Sell Your Products Online
              </h2>
              <h3 className="text-2xl text-gray-200">
                E-commerce Solution
              </h3>
              <p className="text-xl text-gray-300">
                Transform your business with a complete online store. Includes everything from the Basic package plus tools to sell your products online.
              </p>

              <div className="space-y-4 mt-8">
                <div className="flex items-center gap-3">
                  <ArrowRight className="text-accent h-6 w-6" />
                  <span className="text-lg text-gray-200">Manage your inventory efficiently</span>
                </div>
                <div className="flex items-center gap-3">
                  <ArrowRight className="text-accent h-6 w-6" />
                  <span className="text-lg text-gray-200">Process payments securely</span>
                </div>
                <div className="flex items-center gap-3">
                  <ArrowRight className="text-accent h-6 w-6" />
                  <span className="text-lg text-gray-200">Track orders and shipping in real-time</span>
                </div>
              </div>

              <div className="space-y-4">
                <div className="inline-block bg-white/20 border-3 border-accent rounded-lg p-4 transform rotate-2 shadow-lg backdrop-blur-sm">
                  <span className="text-white font-bold text-xl bg-accent px-2 py-1 rounded">Limited Time Offer! 🎉</span>
                  <div className="flex items-baseline gap-2 mt-2">
                    <span className="line-through text-gray-200 text-lg">$65/month</span>
                    <span className="text-4xl font-bold text-white">$29/month</span>
                  </div>
                  <span className="text-white font-semibold bg-accent/30 px-2 py-1 rounded mt-1 inline-block">
                    Less than Netflix + Disney+ combined! 🎬
                  </span>
                </div>

                <div className="flex gap-4">
                  <a 
                    href="#contact"
                    className="inline-block bg-accent hover:bg-accent/90 text-white font-semibold py-4 px-8 rounded-lg transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl text-lg"
                  >
                    Get Started Now
                  </a>
                  <a 
                    href="https://templates.webber.com.ar/ecommerce"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block bg-white hover:bg-gray-50 text-accent border-2 border-accent font-semibold py-4 px-8 rounded-lg transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl text-lg"
                  >
                    See Demo
                  </a>
                </div>
              </div>
            </div>

            <div className="order-1 md:order-2 relative" style={{ minHeight: '180px' }}>
              <div
                className="shadow-2xl rounded-lg overflow-hidden"
                style={{ transform: 'rotate(6deg)' }}
              >
                <img 
                  src="/resources/ecommerce.webp" 
                  alt="E-commerce preview" 
                  className="w-full h-auto rounded-lg border border-transparent shadow-lg"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Business Management Suite Section */}
        <div className="bg-gray-100 py-20 px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center max-w-7xl mx-auto">
            <div className="relative" style={{ minHeight: '180px' }}>
              <div
                className="shadow-2xl rounded-lg overflow-hidden"
                style={{ transform: 'rotate(-6deg)' }}
              >
                <img 
                  src="/resources/dashboard.webp" 
                  alt="Business Management preview" 
                  className="w-full h-auto rounded-lg border border-transparent shadow-lg"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="space-y-6">
              <h2 className="text-5xl font-bold leading-tight text-primary">
                Take Control of Your Business
              </h2>
              <h3 className="text-2xl text-gray-700">
                Business Management Suite
              </h3>
              <p className="text-xl text-gray-600">
                Take full control of your business with our complete solution. Includes e-commerce plus a powerful dashboard to track inventory, sales, and analytics.
              </p>

              <div className="space-y-4 mt-8">
                <div className="flex items-center gap-3">
                  <ArrowRight className="text-accent h-6 w-6" />
                  <span className="text-lg">Monitor your business performance in real-time</span>
                </div>
                <div className="flex items-center gap-3">
                  <ArrowRight className="text-accent h-6 w-6" />
                  <span className="text-lg">Optimize your operations with data-driven insights</span>
                </div>
                <div className="flex items-center gap-3">
                  <ArrowRight className="text-accent h-6 w-6" />
                  <span className="text-lg">Enhance customer satisfaction with better service</span>
                </div>
              </div>

              <div className="space-y-4">
                <div className="inline-block bg-accent/15 border-3 border-accent rounded-lg p-4 transform -rotate-2 shadow-lg">
                  <span className="text-accent font-bold text-xl bg-yellow-100 px-2 py-1 rounded">Incredible Savings! 🎉</span>
                  <div className="flex items-baseline gap-2 mt-2">
                    <span className="line-through text-gray-600 text-lg">$95/month</span>
                    <span className="text-4xl font-bold text-accent">$49/month</span>
                  </div>
                  <span className="text-accent font-semibold bg-white/80 px-2 py-1 rounded mt-1 inline-block">
                    Less than your phone plan! 📱
                  </span>
                </div>

                <div className="flex gap-4">
                  <a 
                    href="#contact"
                    className="inline-block bg-accent hover:bg-accent/90 text-white font-semibold py-4 px-8 rounded-lg transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl text-lg"
                  >
                    Get Started Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}